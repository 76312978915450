import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import {
  HalfWidthBlock,
  Medailon,
  PaddingWrapper,
  ReferenceContainer,
  TitleH2,
} from "@igloonet-web/shared-ui"
import ReferenceBanner from "@igloonet-web/shared-ui/components/reference/reference-banner"
import ReferenceBannerContent from "@igloonet-web/shared-ui/components/reference/reference-banner-content"

import Logo from "../../../images/reference/mupo/logomupo.png"
import Top from "../../../images/reference/mupo/top.jpg"
import Header from "../../../images/reference/mupo/header-marketing.png"
import Martin from "../../../images/reference/mupo/reaction-marketing.png"

class Mupo extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    const pozadi = {
      backgroundImage: `linear-gradient(rgba(10,75,54,0.9),rgba(0,49,85,0.9)),url(${Top})`,
    }

    return (
      <Layout>
        <Helmet>
          <title>Reference na marketingové práce pro MÜPO | igloonet</title>
          <meta
            name="description"
            content="Marketing, vývoj a hosting pod střechou jedné firmy. Programujeme moderní eshopy, poskytujeme prvotřídní hostingové služby a tvoříme marketingové strategie na míru."
          />
        </Helmet>

        <ReferenceBanner
          styles={pozadi}
          logo={Logo}
          alt="logo"
          extodkaz="//mupo.cz/"
          heading="Reference na marketingové práce pro MÜPO"
          odstavec="2014 - současnost"
        >
          <ReferenceBannerContent
            image={{
              src: Header,
            }}
            mainText="Firma MÜPO, spol. s r. o. byla založena v roce 1992 jako specializovaná stavební firma zaměřená na výstavbu a rekonstrukce všech typů střech, a to v plném rozsahu klempířských, pokrývačských a tesařských prací. Postupem času se činnost firmy rozšířila o výstavbu kompletních střešních vestaveb a nástaveb včetně realizace interiérů suchou výstavbou. Za dobu svojí existence si firma upevnila svoji pozici na trhu a stala se renomovanou střechařskou firmou v regionu Jižní Moravy, jejíž služby využívá řada velkých stavebních společností."
          />
        </ReferenceBanner>

        <ReferenceContainer>
          <HalfWidthBlock>
            <PaddingWrapper>
              <TitleH2>Zadání</TitleH2>
              <p className="text-center">
                Cílem jednotlivých kampaní vždy bylo získat nové zájemce na
                právě volné pracovní pozice.
              </p>
            </PaddingWrapper>
            <PaddingWrapper>
              <TitleH2>Rozsah projektu</TitleH2>
              <p>
                V březnu 2014 jsme realizovali přes Facebook Ads a Seznam Sklik
                první placenou náborovou kampaň. Díky jejímu úspěchu jsme dodnes
                realizovali celkem osm kampaní a další budou následovat.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Historie spolupráce</TitleH2>
              <p>
                Pro stavební firmu MÜPO jsme v minulosti vytvořili webové
                stránky, které jsou však dnes již zastaralé. V blízké
                budoucnosti proto budeme vyvíjet nový web.
              </p>
            </PaddingWrapper>

            <PaddingWrapper>
              <TitleH2>Zhodnocení zadavatele</TitleH2>
              <p>
                Řešili jsme nedostatek zájemců o volná pracovní místa a díky
                kampaním se nám podařilo v krátké době získat nové klempíře,
                tesaře a v sezóně také řadu brigádníků. V roce 2016 budeme
                spolupracovat na propagaci naší předváděcí akce.
              </p>
            </PaddingWrapper>
          </HalfWidthBlock>

          <Medailon
            wide
            image={Martin}
            alt=""
            text="Díky kampaním se nám podařilo v krátké době získat nové klempíře, tesaře a brigádníky."
            name="Martin Polášek"
            position="jednatel"
          />
        </ReferenceContainer>

        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default Mupo
